/// <references types="houdini-svelte">

/** @type {import('houdini').ConfigFile} */
export default {
	watchSchema: {
		url: 'https://sprenger-store.myshopify.com/api/2023-10/graphql.json',
		interval: 0,
		headers: {
			'X-Shopify-Storefront-Access-Token': `bc6f9c33b63532fec2de73161829e898`
		}
	},
	plugins: {
		'houdini-svelte': {
			client: './src/lib/client.ts'
		}
	},
	defaultFragmentMasking: 'disable',
	scalars: {
		URL: {
			type: 'URL',
			marshal: (value) => value.toString(),
			unmarshal: (value) => new URL(value)
		},
		DateTime: {
			type: 'Date',
			marshal: (value) => value.toISOString(),
			unmarshal: (value) => new Date(value)
		},
		HTML: {
			type: 'string',
			marshal: (value) => value.toString(),
			unmarshal: (value) => value
		},
		Decimal: {
			type: 'number',
			marshal: (value) => value.toString(),
			unmarshal: (value) => Number(value)
		}
	}
};
